// Core

@import 'argon-dashboard/custom/functions';
@import 'argon-dashboard/custom/variables';
@import 'argon-dashboard/custom/mixins';

// bootstrap (4.6.0) components

@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/jumbotron';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/print';

// Utilities and components

@import 'argon-dashboard/custom/reboot';
@import 'argon-dashboard/custom/utilities';
@import 'argon-dashboard/custom/components';

// Vendor (Plugins)

@import 'argon-dashboard/custom/vendors';

// Docs components

@import 'argon-dashboard/docs/variables';
@import 'argon-dashboard/docs/nav';
@import 'argon-dashboard/docs/clipboard-js';
@import 'argon-dashboard/docs/component-examples';
@import 'argon-dashboard/docs/prism';
@import 'argon-dashboard/docs/content';
@import 'argon-dashboard/docs/sidebar';
@import 'argon-dashboard/docs/footer';

// React Differences
@import 'react/react-differences';

// Manual styles

body,
html {
  overflow-x: hidden;
  -webkit-text-size-adjust: none;
  width: 100%;
}

// #root {
//   padding-top: env(safe-area-inset-top);
// }

.mobile-top {
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  background-color: #fafafb !important;
}

.nav-tabs {
  .nav-link.active {
    border-bottom: 2px solid #5e72e4 !important;
  }
}

.pr-2-px {
  padding-right: 2px;
}

.pl-2-px {
  padding-left: 2px;
}

@media (max-width: 450px) {
  .modal-dialog {
    padding-top: env(safe-area-inset-top);
    margin-top: env(safe-area-inset-top);
  }
}

@media (max-width: 834px) {
  .h2 {
    font-size: 1rem !important;
  }
  .fc {
    .fc-toolbar-title {
      font-size: 1.2em !important;
    }
    .fc-daygrid-day-top {
      font-size: 12px;
    }
    .fc-daygrid-event-harness {
      font-size: 0.85em;
    }
    .fc-daygrid-event {
      justify-content: left !important;
    }
    a.fc-event {
      padding: 0;
      padding-left: 2px;
    }
    .fc-view-harness {
      min-height: 320px;
    }
  }
  .nav-profile {
    text-align: center;
    .nav-link-profile {
      text-align: center;
      span {
        display: block;
        font-size: 9px;
      }
    }
  }
}

@media (max-width: 1230px) {
  .pl-5-media {
    padding-left: 3rem !important;
  }
}

.sig-canvas {
  border: 1px solid #ccc;
}

.map-container {
  height: 100%;
  width: 100%;
}

.fc-daygrid-day-top {
  text-align: right;
  float: right;
  .fc .fc-daygrid-day-number {
    float: right;
  }
}
.card-select {
  cursor: pointer !important;
}

.card-selected {
  background: #3c5a9c !important;
  color: #fff;
}

// Spinner wrapper
.loader-wrapper {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(255, 255, 255, 0.7);
}

// Keypad
.buttons-numbers .col .btn {
  margin: 20px;
}
.buttons-numbers .col {
  text-align: center;
}

// Full Calendar
.fc-button-primary {
  color: #fff !important;
  background-color: #5e72e4 !important;
  border-color: #5e72e4 !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}
.fc-button-primary:hover {
  color: #fff !important;
  background-color: #5e72e4 !important;
  border-color: #5e72e4 !important;
}
.fc-button-primary:focus,
.fc-button-primary.focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(94, 114, 228, 0.5) !important;
}
.fc-button-primary.disabled,
.fc-button-primary:disabled {
  color: #fff !important;
  background-color: #5e72e4 !important;
  border-color: #5e72e4 !important;
}
.fc-button-primary:not(:disabled):not(.disabled):active,
.fc-button-primary:not(:disabled):not(.disabled).active,
.fc-button-primary:not(:disabled):not(.disabled).fc-button-active,
.show > .fc-button-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #324cdd !important;
  border-color: #5e72e4 !important;
}

.fc-button-primary:not(:disabled):not(.disabled):active:focus,
.fc-button-primary:not(:disabled):not(.disabled).active:focus,
.show > .fc-button-primary.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(94, 114, 228, 0.5) !important;
}

.fc-toolbar-title {
  color: #8898aa !important;
}

.fc-daygrid-event {
  overflow: hidden !important;
  text-align: center;
  align-items: center;
  justify-content: center;
  // b {
  //   margin-left: 5px !important;
  // }
  i {
    margin-left: 5px !important;
    font-style: normal !important;
  }
}

.fc-daygrid-event-harness {
  cursor: pointer !important;
}

.daygrid-day-events-success {
  background-color: #2dce89 !important;
  color: #fff !important;
  border: 1px solid #5e72e4;
}

.daygrid-day-events-yellow {
  background-color: #ffd600 !important;
  color: #5e72e4 !important;
  border: 1px solid #5e72e4;
}

.daygrid-day-events-blue {
  background-color: #5e72e4 !important;
  color: #fff !important;
  border-color: black !important;
}

.daygrid-day-events-blue-no-border {
  background-color: #5e72e4 !important;
  color: #fff !important;
}

.daygrid-day-events-black {
  background-color: #212529 !important;
  color: #fff !important;
  border-color: #212529 !important;
}

.daygrid-day-events-orange {
  background-color: #fb6340 !important;
  color: #fff !important;
  border: 1px solid #5e72e4;
}

.daygrid-day-events-red {
  background-color: #fc0000 !important;
  color: #fff !important;
  border: 1px solid #5e72e4 !important;
}

.schedule-calendar-event-red {
  background-color: #fc0000 !important;
  color: #fff !important;
  border: 1px solid #5e72e4 !important;
  border-color: #5e72e4 !important;
}

.daygrid-day-events-white {
  background-color: #fff !important;
  border: 1px solid #5e72e4;
}

.daygrid-day-events-blank {
  background-color: inherit !important;
  color: #324cdd !important;
  border: 1px solid #5e72e4;
}

.daygrid-day-events-blank .fc-event-main {
  color: inherit !important;
}

@media (max-width: 820px) {
  .schedule-settings-modal {
    .fc-button-group {
      margin-bottom: 10px !important;
    }
  }
  .fc-popover {
    z-index: 1030 !important;
  }
}

// Dash Info

@media (max-width: 1670px) {
  .dash-info-list {
    font-size: 12px !important;
  }
}

@media (max-width: 1394px) {
  .dash-info-list {
    margin-left: 70px !important;
  }
}

@media (max-width: 1055px) {
  .dash-info-list {
    font-size: 10px !important;
  }
}

@media (max-width: 992px) {
  .card-stats {
    min-height: 150px !important;
  }
  .dash-info-list {
    margin-left: 78px !important;
    font-size: 12px !important;
  }
}

// .card-info-button {
//   margin-right: -70px;
// }
// .card-info-mg-button {
//   margin-right: -70px !important;
// }

// Time Picker

.rc-time-picker-input {
  width: 100% !important;
  padding-left: 0 !important;
  padding: 0.625rem 0.75rem !important;
  margin-top: -3px !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #8898aa !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: none !important;
  box-shadow: none !important;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) !important;
}

.rc-time-picker-input[disabled] {
  background-color: #f7f7f7 !important;
}

.rc-time-picker.disabled {
  background-color: #f7f7f7 !important;
}

.disabled .input-group-text {
  background-color: #f7f7f7 !important;
}

.rc-time-picker-clear {
  top: 10px !important;
}

@media (max-width: 1429px) {
  .select-search-container {
    width: 700px !important;
  }
}

@media (max-width: 1334px) {
  .select-search-container {
    width: 600px !important;
  }
}

@media (max-width: 1249px) {
  .select-search-container {
    width: 500px !important;
  }
}

// @media (max-width: 1134px) {
//   .navbar-input-search {
//     display: none !important;
//   }
// }

.navbar-notifications {
  margin-left: auto !important;
}

// border: 1px solid #cad1d7;
//
.img-invert-color {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.card-stats {
  min-height: 165px;
}

select {
  -webkit-appearance: none;
}

.folderDelete {
  margin: 0 auto !important;
  width: 90%;
}

.hover-transition-disabled {
  &:hover {
    transition: none !important;
    transform: none !important;
  }
}

// .messaging-list-talk-mainRow {
//   max-width: 400px;
//   // border: 1px solid;
// }
.messaging-card-blank {
  height: 80vh;
}

.messaging-blank-cardbody {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  height: fit-content;
}

.messaging-list-talk-col-talk {
  height: 80vh;
  overflow-y: scroll;
}

.messaging-card-header-title {
  text-align: center;
}

.messaging-card-header-title h1 {
  font-size: 16px;
  text-align: center;
}

.messaging-card-header-title i {
  font-size: 5vw;
}

.messaging-card-header-text {
  text-align: center;
}

.messaging-blank-cardbody .row div {
  padding-bottom: 15px;
}

.messaging-search {
  margin-bottom: 0;
}

.messaging-group-direct-type {
  font-size: 0.75em;
}

.messaging-time-list {
  font-size: 0.75em;
}

.messaging-talk-main {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 65vh;
}

.messaging-unread {
  background-color: #98fb98;
}

.messaging-card-list-read {
  background-color: #f2f2f2;
}

.messaging-filter-star .fas,
.messaging-filter-star .far {
  padding-top: 13px;
  cursor: pointer;
}

.messaging-list-loop-main .fas.fa-star,
.messaging-list-loop-main .far.fa-star {
  cursor: pointer;
}

.messaging-filter-icons {
  padding-top: 15px;
  text-align: center;
}

.messaging-filter-icons i {
  cursor: pointer;
}

.messaging-hidden {
  display: none;
}

.home-waitinglist-header {
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  border-bottom-style: solid;
  background-color: #f6f9fc;
  color: #8898aa;
  min-height: 52px;
  font-size: 0.65rem;
  padding-top: 1.25rem;
  padding-bottom: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  vertical-align: middle;
  font-weight: 600;

  align-items: center;
}

.home-waitinglist-body {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);

  align-content: stretch;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.8125rem;
  font-weight: 400;
  color: #525f7f;
  background-color: #ffffff;
  min-height: 65px;
  white-space: nowrap;
  vertical-align: middle;

  align-items: center;
  box-sizing: border-box;
  line-height: normal;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  word-break: break-word;
  font-size: 0.8125rem;
}

.home-waitinglist-lastname {
  font-weight: bold;
}

.home-waitinglist_table .row {
  width: 100%;
}

.home-waiting_listCardBody {
  padding-top: 0;
}

.home-waitingList {
  .table {
    th,
    td {
      @media (max-width: 715px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
}

.vertical-timeline-element-content {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  padding-top: 8px !important;
  .vertical-timeline-element-date {
    display: none !important;
  }
  .timeline-date-container {
    width: 100%;
    display: block;
    float: left;
  }
  .timeline-date {
    opacity: 0.7;
    font-size: 11px;
    float: right;
    display: block;
  }
}
.vertical-timeline::before {
  width: 3px !important;
  background: #a9bfd630 !important;
}

.breadcrumb {
  .breadcrumb-item {
    font-size: 18px;

    &::before {
      content: '';
    }

    a {
      color: rgba(255, 255, 255, 1);

      &:hover {
        color: rgba(255, 255, 255, 0.7);
      }
    }

    &.active {
      color: rgba(255, 255, 255, 0.7);
    }
  }
}

.parentsDash {
  background-color: #fff;
  .breadcrumb {
    .breadcrumb-item.active {
      &::before {
        content: '/';
        font-size: 30px;
      }
    }
  }
}

.parent-events-calendar,
.calendar-monthly-info {
  .fc-col-header-cell-cushion {
    color: #5e72e4b8;
  }
  .fc .fc-timegrid-divider {
    padding: 0;
  }
  .fc-scrollgrid-shrink {
    color: #8898aa;
    font-size: 14px;
    text-align: center;
  }
  .fc-theme-standard td,
  .fc-theme-standard th {
    border: 1px solid #f5f5f5;
  }
  .fc-timegrid-event {
    p {
      font-size: 11px !important;
    }
  }
  .fc-daygrid-event {
    white-space: normal;
    p {
      font-size: 11px !important;
    }
  }
}

.divider {
  border-top: none;
  height: 1px;
  background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.4), transparent);
  opacity: 0.25;
}

.font-size-25 {
  font-size: 25px;
}

.cursor-pointer {
  cursor: pointer;
}

.text-uppercase {
  text-transform: uppercase;
}

// .modal-body .fc-daygrid.fc-dayGridMonth-view.fc-view {
//   height: 422.963px; //fc-dom-62 aria-labelledby
// }

.modal-body .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  height: 481px;
  width: 100%;
}

.schedule-calendar-event-red,
.schedule-calendar-event-blue,
.schedule-calendar-event-green,
.schedule-calendar-event-black,
.schedule-calendar-event-white {
  text-align: center !important;
  padding: 0px !important;
}

.schedule-calendar-event-red,
a.schedule-calendar-event-red:hover {
  background-color: #f96868 !important;
  color: #ffffff !important;
  border-color: #5e72e4 !important;
}

.schedule-calendar-event-blue,
a.schedule-calendar-event-blue:hover {
  background-color: #3a87ad !important;
  color: #ffffff !important;
  border-color: #3a87ad !important;
}

.schedule-calendar-event-green,
a.schedule-calendar-event-green:hover {
  background-color: #1e7e34 !important;
  color: #ffffff !important;
  border-color: #1e7e34 !important;
}

.schedule-calendar-event-black,
a.schedule-calendar-event-black:hover {
  background-color: #000000 !important;
  color: #ffffff !important;
  border-color: #000000 !important;
}

.schedule-calendar-event-white,
a.schedule-calendar-event-white:hover {
  background-color: #ffffff !important;
  color: #000000 !important;
  border: 1px solid #000000 !important;
}

.react-confirm-alert-overlay {
  z-index: 2500 !important;
}

.big-font {
  font: {
    weight: 900;
    size: 40px;
  }
}

.aside-profile {
  margin-left: 1.5rem;

  @media only screen and (max-width: 992px) {
    margin-left: 0px;
    margin-top: 1rem;
  }
}

.card-profile {
  &.kids {
    @media only screen and (max-width: 992px) {
      min-width: 188px !important;
    }
    @media (max-width: 576px) {
      min-width: 168px !important;
    }
    @media (max-width: 395px) {
      min-width: 145px !important;
    }
  }
}

// react-calendar
.react-add-to-calendar {
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  position: relative;
  display: inline-block;
  margin: 0 auto;
}
.react-add-to-calendar__wrapper {
  zoom: 1;
  cursor: pointer;
}
.react-add-to-calendar__button {
  padding: 10px;
  border-radius: 5px;
  position: relative;
  text-transform: none;
  transition: all 0.15s ease;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  will-change: transform;

  color: #fff;
  background-color: #5e72e4;
  border-color: #5e72e4;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

  padding: 0.875rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.4375rem;
}
.react-add-to-calendar__button:hover {
  color: #fff;
}

.react-add-to-calendar__icon--right {
  padding-left: 5px;
}
.react-add-to-calendar__icon--left {
  padding-right: 5px;
}
.react-add-to-calendar__dropdown {
  z-index: 9999;
  position: absolute;
  top: 30px;
  left: 1px;
  width: 93%;
  padding: 5px 0 5px 8px;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
  border: 1px solid #a8a8a8;
  background-color: #5e72e4;
  text-align: left;
}
.react-add-to-calendar__dropdown ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin: 0;
}
.react-add-to-calendar__dropdown ul li {
  padding: 5px 0;
}

.react-add-to-calendar__dropdown ul li a {
  color: #fff;
  text-decoration: none;
}
.react-add-to-calendar__dropdown ul li a i {
  padding-right: 10px;
}

.custom-datepicker .rdtPicker {
  width: 250px;
}
.calendar-app-main .fc-daygrid-event div,
.calendar-app-lunch .fc-daygrid-event div {
  width: 100%;
}

.pdf-thumb {
  height: 250px;
}

.react-pdf__Page__annotations {
  height: auto !important;
}

.react-pdf__Page {
  min-width: auto !important;
}

.react-pdf__Page__canvas {
  height: 100% !important;
  width: 100% !important;
}

.page-space {
  margin-top: 20px;
}

.row-header {
  padding: 10px 0px;
  color: #fff;
  font-weight: bold;
}

.flutter-options {
  position: fixed;
  top: 200px;
  right: 0;
  z-index: 999999;
}

.flutter-options .dropdown-menu {
  padding: 0;
  margin: 0;
}

.msg-arrow {
  position: absolute;
  padding: 10px;
  box-shadow: 1px -1px 0 1px rgba(0, 0, 0, 0.05) inset;
  background-color: #fff;
  top: 3px;
}

.msg-arrow.left {
  left: -11px;
  transform: rotate(45deg);
}

.msg-arrow.right {
  right: -11px;
  transform: rotate(225deg);
}

.profilepic-message {
  position: absolute;
  top: -8px;
}

.profilepic-message.left {
  left: -60px;
}

.profilepic-message.right {
  right: -70px;
}

.pagination .dvSlcPage {
  position: relative;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: inherit;
  color: inherit;
  margin-top: 1px;
}

.pagination .dvSlcPage select {
  cursor: pointer;
  height: 24px;
  max-width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 8px;
  padding-right: 24px;
  box-sizing: content-box;
  font-size: inherit;
  color: inherit;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  direction: ltr;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.pagination .controlls {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  white-space: nowrap;
}

.pagination .actionButtons:disabled {
  cursor: unset;
  color: rgba(0, 0, 0, 0.18);
  fill: rgba(0, 0, 0, 0.18);
}
.pagination .actionButtons {
  position: relative;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 8px;
  margin: px;
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  color: rgba(0, 0, 0, 0.54);
  fill: rgba(0, 0, 0, 0.54);
  background-color: transparent;
}

.billing-parents-info .card-stats:hover {
  border-bottom: 5px solid rgb(94, 114, 228);
}

.transaction-type {
  position: relative;
  display: block;
  background: #e8e9ea;
  width: fit-content;
  padding: 3px 15px 0;
  font-size: 10px;
  color: #909094;
  border-radius: 10px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 260px;
  cursor: pointer;
  transition: all 0.1s;
  min-height: 20px;
}

.transaction-type:hover {
  background: #fff;
  box-shadow: inset 0 0 0 1px #909094;
  color: #909094;
}

.profileRightBar .card-header {
  padding: 10px 20px;
  text-align: center;
}

.profileRightBar .card-header.bg-danger {
  color: #fff;
}

.profileRightBar .card-body {
  padding: 1.25rem;
}

.giant-number {
  text-align: center;
  font-size: 28px;
}

.profileRightBar .card-footer {
  padding: 10px 20px;
  text-align: center;
}

.arb__menu-portal {
  min-width: 230px;
}

.show-navbar {
  width: calc(100% - 1.4rem);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1050;
  margin: 0.7rem;
  overflow-y: auto;
  height: auto !important;
  /* opacity: 0; */
  padding: 1.5rem;
  border-radius: 0.375rem;
  background: #fff;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
  -webkit-animation: show-navbar-collapse 0.2s ease forwards;
  animation: show-navbar-collapse 0.2s ease forwards;

  margin-left: 0.7rem !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.show-navbar .navbar-collapse-header {
  display: block;
}
.show-navbar .navbar-toggler {
  width: 25px;
  height: 25px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 0;
}

.show-navbar .navbar-toggler span {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  opacity: 1;
  background: #283448;
}

.show-navbar .navbar-toggler :nth-child(1) {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.show-navbar .navbar-toggler :nth-child(2) {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.menu-route-principal > li > a,
.menu-route-principal > li > button {
  padding-left: 24px !important;
  margin-left: 0 !important;
}

.fluid-tablet {
  width: 100%;
  padding-right: 2px;
  padding-left: 2px;
  margin-right: 0;
  margin-left: 0;
}

.table-timecard-mobile p {
  font-size: 0.8125rem;
  padding: 5px;
}
